









































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ApproveRemovePassword',
  components: {
    BasePictureWithText: () => import('./BasePictureWithText.vue'),
  },
})
export default class ApproveRemovePassword extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly loading!: boolean

  @Prop({
    type: String,
    default: '',
  })
  readonly errLabel!: string
}
